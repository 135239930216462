.text-field-array-container {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.text-field-border {
  border-right: none;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.trash {
  background-color: $red;
  text-align: center;
  display: inline-block;
  i {
    margin-top: 50%;
    width: 25px;
    cursor: pointer;
  }
}

.trash-border {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.add {
  background-color: $green;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: inline-block;
  i {
    margin-top: 50%;
    width: 25px;
    cursor: pointer;
  }
}

.reset-flex {
  flex-wrap: nowrap;
}

.reset-padding {
  padding: 0;
}

.reset-height {
  height: 100%;
}

.reset-margin {
  margin-left: unset;
}

.textbox-flex {
  flex: 0.99;
}

.not-active {
    pointer-events: none;
    opacity: 0.6;
}

@media only screen and (max-width: 1150px)
{
.reset-flex {
    height: 28px;
}
}

