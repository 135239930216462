.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    width: 95%;
    margin-left: 15px;
    overflow-y: scroll;
    max-height: 300px;
    padding-top: 0;
    margin-top: 0;
  }

  .autocomplete-items div { 
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #d4d4d4; 
  }

  .autocomplete-items div:hover {
    background-color: #e9e9e9; 
  }

  .autocomplete-active {
    background-color: DodgerBlue !important; 
    color: #ffffff; 
  }