.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 100;

  .row:last-of-type {
    margin-top: 10px;

    .form-group {
      margin-bottom: 0;
    }
  }
}

.popup-inner {
  position: fixed;
  z-index: 1061;
  background-color: #fff;
  top: 50%;
  height: auto;
  min-width: 300px;
  max-width: 1000px;
  left: 50%;
  border: 1px solid #d4d4d4;
  padding: 25px 20px;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
}

.popup--confirm-delete {
  .button-area {
    margin-top: 20px;
  }

  .col-12 {
    text-align: center;
  }
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 auto;
  margin-bottom: 10px;

  i {
    color: white;
    margin-top: 10px;
    margin-left: 3px;
  }
}