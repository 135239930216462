.badge-primary {
    background-color: #d60925;;
  }
  
  .badge-success {
    background-color: #5cb85c;
  }
  
  .badge-info {
    background-color: #5bc0de;
  }
  
  .badge-warning {
    background-color: #f0ad4e;
  }
  
  .badge-danger {
    background-color: #d9534f;
  }

  .badge-default {
      background-color: gray;
  }

  .badge {
      height: 25px;
      width: 25px;
      border-radius: 10px;
  }

  .hcp-pull-right {
      text-align: right;
  }

  .hcp-management-edit-delete {
      color: #d60925;
  }

  .label-outline {
    border: 1px solid;
    background: none;
  }
  .label-outline-primary {
      border-color: #d60925;
      color: #d60925;
    }
  
   .label-outline-success {
      border-color: #5cb85c;
      color: #5cb85c;
    }
  
    .label-outline-info {
      border-color: #5bc0de;
      color: #5bc0de;
    }
  
    .label-outline-warning {
      border-color: #f0ad4e;
      color: #f0ad4e;
    }
  
    .label-outline-danger {
      border-color: #d9534f;
      color: #d9534f;
    }

    .filter-rounded {
      border-radius: 10px;
    }

    .org-accordion-item{
        padding-top: 0px !important;
        padding-bottom: 8px !important;
    }

    .org-accordion-arrow{
        height: 15px !important;
    }

    .font-searched-org-practice{
        font-style: italic;
        font-weight: 500;
    }