.primary-button-edit-country {
  background: $green;
  width: 100%;

  &:hover {
    background-color: darken($green, 10%);
    color: $white;
  }
}

.secondary-button-decline-hcp {
  background: $red;
  width: 100%;

  &:hover {
    background-color: darken($red, 10%);
    color: $white;
  }
}

.tab-country {
  padding: 3rem 6rem;
}

.country-code-modal-body {
  padding: 1.5rem;
}

.country-code-modal {
  .modal-body textarea {
        width: 22.5rem;
        height: 8rem;
        resize: none;
  }
}

.table-div {
  padding-left: 2%;
  padding-right: 2%;
}

.table {
  width: 80%;
}

.country-calling-code {
  .table {
    width: 100%;

    i {
      cursor: pointer;
    }
  }
}

.country-management.container-fluid {
  margin-left: 3rem;
  margin-right: 3rem;
}

.country-description-truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18rem;
  padding-right: 1rem;
}

.country-description-truncate:focus:after,
.country-description-truncate:hover:after {
  content:attr(data-text);
  overflow: visible;
  background: #ffffff;
  position: absolute;
  max-width: 25rem;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
  white-space: normal;
  word-wrap: break-word;
  display:block;
  color:black;
  margin-top:.5rem;
  border-radius: 5%;
}