.loading {
    @extend .fade;
  
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 5;
    left: 0px;
    top: 0px;
  
    &.invisible {
      opacity: 0.0;
    }
    &.visible {
      opacity: 0.6;
    }
  
    .wrapper {
      height: 100%;
      width: 100%;
      display: table;
     
      .inner {
        width: 100%;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
  
        i {
          opacity: 0.5;
        }
      }
    }
  }