.table {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 40px;

  td {
    word-wrap: break-word;
    word-break: break-word;
  }

  >thead>tr>th {
    background-color: white;
    color: #212529;
    border: 1;
    padding: 10px 3px 10px;

    & i {
      color: #828282;
    }
  }

  >tbody>tr {
    >td {
      border: 0;
      border-bottom: 1px solid #b4b8bb;
      padding: 8px 3px 8px;
    }

    &:hover {
      background-color: fade-out($color: #b4b8bb, $amount: 0.8);
    }
  }
}

.table--users {

  .col-1,
  .col-sm-1,
  .col-md-1 {
    width: 8.3333%;
  }

  .col-2,
  .col-sm-2,
  .col-md-2 {
    width: 16.6666%;
  }

  .col-3,
  .col-sm-3,
  .col-md-3 {
    width: 24.9999%;
  }

  .col-4,
  .col-sm-4,
  .col-md-4 {
    width: 33.3333%;
  }

  thead th {
    white-space: nowrap;
  }
}

.table-nonfluid {
  width: auto !important;
}


.serach-account {
  tbody {
    tr {
      .pl-3 {
        padding-left: 3rem !important;
      }

      td {
        border-bottom: unset;
        padding-bottom: 2rem;

        .fa-check {
          color: #78b929ed;
        }

        .fa-times {
          color: red;
        }
      }
    }

    tr:nth-child(even) {
      border-bottom: 1px solid #b4b8bb
    }
  }
}