.form-group {
    > label {
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

.primary-button {
    background: $green;
    border-color: $green;
    width: 100%;

    &:hover {
        background-color: darken($green, 10%);
        border-color: darken($green, 10%);
        color: $white;
    }

    &:disabled {
      background: $green;
      border-color: $green;
  }
}