.bread-crumbs {
  display: flex;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: black;
  }

  div span a {
    text-decoration: none;
    color: black;
  }
}

.bread-crumbs .fa-lg {
  line-height: 0.95em !important;
}

.input-wrapper {
  margin: auto;
  width: 100%;
  border-radius: 5px;
  font-size: 1.2em;

  @media only screen and (max-width: 1150px) {
    height: 28px;
  }
}

.form-group .field-object {
  >label {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.2em;
    text-transform: uppercase;
  }
}

.control-label {
  font-weight: bold;
  font-size: 1.1rem;
}

.checkbox {
  display: inline-block;
  border-radius: 2px;
  width: 20px !important;
  height: 20px !important;
  margin: 8px 10px 10px 0;
  box-shadow: none !important;
}

.has-error {
  color: red;
}

.pagination {
  .active>a {
    z-index: 3;
    color: #fff;
    background-color: #d60925;
    border-color: #d60925;
    cursor: default;
  }

  >li {
    display: flex;

    >a {
      position: relative;
      padding: 6px 12px;
      line-height: 1.42857143;
      text-decoration: none;
      color: #d60925;
      background-color: #fff;
      border: 1px solid #ddd;
      margin-left: -1px;
    }
  }
}

.edit-delete-button {
  color: #d60925;
  cursor: pointer;
}

.error-Message {
  color: #d60925;
}

.admin_role_form {
  input {
    padding: 10px;
    display: initial;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .checkbox {
    display: block;
    padding: 10px;

  }
}

.checkbox-default {
  height: 18px !important;
  width: 18px !important;
  vertical-align: middle;
}


.disabled-div {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}

.enabled-div {
  cursor: auto;
  pointer-events: auto;
  opacity: 1.0;
}

.success-icon {
  color: $green;
  margin-left: 1rem;
}

.unsuccessful-icon {
  color: $red;
  margin-left: 1rem;
}