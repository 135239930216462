$patient-navigation-color: #e6e6e6;

.patient-navigation {
  z-index: 1;
  background: $patient-navigation-color;
  margin: 10px 0 20px;
  font-size: 16px;

  &__menu {
    margin-bottom: 0;
    padding: 0;

    &.profilePage {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__menu-item {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.active {
      background: $green;

      &:hover {
        background-color: darken($green, 10%);
      }

      a {
        color: $white;
        height: 100%;
      }
    }
  }

  &__menu-link {
    display: block;
    padding: 10px;
    color: $text;
    text-decoration: none !important;
    text-align: center;
    font-weight: bold;

    &.active {
      background: $green;
      color: $white;
      height: 100%;

      &:hover {
        background-color: darken($green, 10%);
        color: $white;
      }
    }

    &:hover {
      color: darken($text, 10%);
    }

    .unread {
      color: #FF0000;
      position: absolute;
      font-size: 8px;
      top: 6px;
    }
  }

  .fa-cog {
    padding: 9px;
    background: transparent;
    border: none;
    color: $text;
    font-size: 22px;
  }
}

.navigation-bar {
  margin-bottom: 25px;
  z-index: 5;
  background-color: white;
}

.version-cursor {
  cursor: auto;
}

.country-management {
  .nav-tabs {
    border: none;

    .nav-link {
      color: $text;
      font-weight: bold;
      background-color: #e6e6e6;
      width: 14rem;
      text-align: center;

      &.active {
        color: $white;
        background-color: $green;

        &:hover {
          background-color: darken($green, 10%);
          color: $white;
        }
      }
    }
  }
}