.navbar-brand a {
    color: black;
    text-decoration: none;
}

.navbar-nav a {
    background-image: none;
    color: white;
    
}

.navbar-nav .nav-link {
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 0.25rem;
}

.navbar-nav a.active {
    background-color: #4189C7;
}

.btn-primary {
    background-color: #4189C7;
}

.btn-primary.hover, .btn-primary:hover {
    background-color: #2c7fc7;
    border-color: #4189C7;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #79a3c7;
    border-color: #79a3c7;
}