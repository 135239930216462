.accordion {
    border:none;
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__button {
    outline: none;
    background-color:white;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 16px;
    border-top:0.2px solid rgb(223, 219, 219);
}

.accordion__button:before {
    visibility: hidden;
}

.accordion__button:hover {
    background-color: #ddd;   
}

.accordion__panel {
    padding: 20px;
    display: block;
    animation: fadein 0.35s ease-in;
}

.accordion__panel--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__item > *:last-child{
    border-bottom: 0.2px solid gray;
}

.accordion__button > *:last-child,
.accordion__panel > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    width: 24px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    color: gray;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.title__text{
    margin-left:3%;
}
.answer__text{
    margin-bottom:2%;
}
.introduction__text{
    margin : 2% 0% 2% 0%
}

.accordion__button--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__button--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}
